<template>
  <HeaderComponent/>
  <FirstPage/>
  <SecondPage/>
  <ThirdPage/>
  <ForthPage/>
  <LastPage/>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FirstPage from './components/FirstPage.vue'
import SecondPage from './components/SecondPage.vue'
import ThirdPage from './components/ThirdPage.vue'
import ForthPage from './components/ForthPage.vue'
import LastPage from './components/LastPage.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent, FirstPage, SecondPage, ThirdPage, ForthPage, LastPage, 
  },
  methods: {
    scrollTo(location) {
            let elem = document.getElementById(location)
            elem.scrollIntoView({ behavior: 'smooth' });
        }
  }
}
</script>

<style>
#app {
  font-family: "Oxygen", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body{
  margin: 0;
  min-width: 1400px;
  overflow-x: scroll;
}
input{
  font-family: "Oxygen", sans-serif;
}
.accent{
  color: #0500FD !important;
}
.page-content {
    width: 100%;
    height: 100vh;
    min-height: 800px;
}
button{
    border: none;
    font-size: 16px;
    padding: 7px 20px;
    border-radius: 50px;
    font-weight: 600;
    cursor: pointer;
    background-color: transparent;
    border: 2px solid;
}
</style>
