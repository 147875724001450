<template>
    <div class="page-content" id="services" @scroll="handleScroll">
        <div class="left-side">
            <div class="left-side-content">
                <div class="section-titles-container">
                    <div v-for="(section, index) in sections" :key="index" :class="{ active: index === activeSection }"
                        :ref="'section' + index" class="section-title">
                        {{ section }}
                    </div>
                </div>
            </div>
        </div>
        <div class="right-side">
            <div v-for="(section, index) in sections" :key="index" :ref="'content' + index" class="section-content">
                <div>
                    <div class="section-content-title">{{ titles[index] }}</div>
                    <div class="section-content-img-wrapper">
                        <div class="section-content-img" :class="`slide${index}`"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SecondPage",
    data() {
        return {
            sections: [
                "Chart adaptation to specific parameters",
                "Other MMs fail on lower time frame",
                "Hand-drawn chart pattern generation",
                "Catch pumps or dumps on the market",
                "In-built terminal for quick swaps",
                "Anti-wash-trading volume generation"
            ],
            titles: [
                "Prepare the price action right before publishing news",
                "Hourly candle on 5 minutes time frame",
                "When your TA pattern meets the chart",
                "Sell or buy automatically when the deviation % is reached",
                "Quick market actions need fast calculations",
                "Bitfusion Volume generation system makes it different",
            ],
            activeSection: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const contentSections = this.$refs;
            for (let i = 0; i < this.sections.length; i++) {
                const section = contentSections['content' + i][0];
                const rect = section.getBoundingClientRect();
                const gap = window.innerHeight * 0.4
                if (rect.top + gap >= 0 && rect.bottom <= window.innerHeight + gap) {
                    this.activeSection = i;
                    break;
                }
            }
        },
    }
}
</script>
<style scoped>
.page-content {
    height: auto;
    display: flex;
    align-items: stretch;
}

.left-side {
    width: 40%;
}

.section-title {
    font-size: 20px;
}

.section-title.active {
    font-weight: bold;
    color: blue;
    font-size: 24px;
}

.left-side-content {
    height: 100vh;
    position: sticky;
    top: 0px;
    display: flex;
    align-items: center;
    margin-left: 5%;
}

.right-side {
    width: 60%;
    padding: 0 50px;
    box-sizing: border-box;
    background-color: #F1F1F1;
}
.right-side> :last-child {
    border-bottom: none;
}

.section-titles-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.section-content {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 3px solid white
}

.section-content-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 30px;
    max-width: 460px;
}

.section-content-img-wrapper {
    display: flex;
    justify-content: center;
}

.section-content-img.slide0 {
    height: 530px;
    content: url('/public/slides/chart-adopt.svg');
}

.section-content-img.slide1 {
    height: 380px;
    content: url('/public/slides/lower-timeframe.svg');
}

.section-content-img.slide2 {
    height: 430px;
    content: url('/public/slides/handdrawn-chart.svg');
}

.section-content-img.slide3 {
    height: 500px;
    content: url('/public/slides/pumps-dumps.svg');
}

.section-content-img.slide4 {
    height: 290px;
    content: url('/public/slides/costs-calculation.svg');
}

.section-content-img.slide5 {
    height: 420px;
    content: url('/public/slides/anti-wash-trading.svg');
}
</style>