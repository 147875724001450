<template>
    <div :class="['header-wrapper', { 'header-hidden': isHeaderHidden }]">
        <div class="header-container">
            <div class="logo">
                <span class="logo-img"></span>
                <span class="logo-text">Bitfusion</span>
            </div>
            <div class="navigation">
                <a @click="$root.scrollTo('services')">Services</a>
                <a @click="$root.scrollTo('quick-setup')">Quick Setup</a>
                <a @click="$root.scrollTo('features')">Features</a>
                <a @click="$root.scrollTo('contact')" class="demo accent">1 week demo 🚀</a>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'HeaderComponent',
    data() {
        return {
            lastScrollY: 0,
            isHeaderHidden: false,
        };
    },
    methods: {
        handleScroll() {
            const currentScrollY = window.scrollY;
            if (currentScrollY > this.lastScrollY && currentScrollY > 200) {
                this.isHeaderHidden = true;
            } else {
                this.isHeaderHidden = false;
            }
            this.lastScrollY = currentScrollY;
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>
<style>
.header-wrapper {
    top: 0px;
    padding: 20px 0;
    width: 100%;
    position: fixed;
    z-index: 100;
    background-color: #F1F1F1;
    transition: transform 0.3s ease;
}

.header-wrapper.header-hidden {
  transform: translateY(-100%);
}

.header-container {
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
    gap: 15px;
}

.logo-img {
    content: url("/public/logo.png");
    width: 40px;
}

.logo-text {
    font-size: 32px;
    font-weight: 700;
    user-select: none;
}

.navigation {
    display: flex;
    gap: 30px;
    align-items: center;
}

.navigation>a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    cursor: pointer;
}

.navigation>a.demo {
    font-weight: bold;
}
</style>