<template>
    <div class="page-content" id="features">
        <div class="left-side">
            <div class="left-side-content">
                <h1>
                    Get a <span class="accent">free consultation & enjoy 1-week trial</span> of Market-Making services
                </h1>
                <button @click="$root.scrollTo('contact')">Request 1 week demo</button>
            </div>
        </div>
        <div class="right-side">
            <div class="right-side-content">
                <div class="card-row">
                    <div class="card">
                        <div class="card-icon-wrapper">
                            <div class="card-icon flexible-trading-strategies"></div>
                        </div>
                        <div class="card-title">Flexible trading strategies</div>
                        <div class="card-content">
                            Customize trading strategies with an algorithmic bots that calculate costs for
                            any desired price action
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon-wrapper">
                            <div class="card-icon volume-generation"></div>
                        </div>
                        <div class="card-title">Volume generation</div>
                        <div class="card-content">
                            Our competitive, ethical volume generation system avoids wash-trading practices, making
                            organic trades appearance
                        </div>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card">
                        <div class="card-icon-wrapper">
                            <div class="card-icon funds-safety"></div>
                        </div>
                        <div class="card-title">Funds safety</div>
                        <div class="card-content">
                            Maintain full control over the balances with the accesses to all created wallets and vaults
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon-wrapper">
                            <div class="card-icon no-profit-fees"></div>
                        </div>
                        <div class="card-title">No profit-sharing fees</div>
                        <div class="card-content">
                            We do not charge our clients who use our software by themselves without hiring an internal
                            market-making team of traders
                        </div>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card">
                        <div class="card-icon-wrapper">
                            <div class="card-icon telegram-alerts"></div>
                        </div>
                        <div class="card-title">Alerts integration with Telegram</div>
                        <div class="card-content">
                            Our Telegram bot keeps the trading team and clients informed about market actions sending
                            custom alerts 24/7
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon-wrapper">
                            <div class="card-icon analytical-dashboard"></div>
                        </div>
                        <div class="card-title">Analytical dashboard</div>
                        <div class="card-content">
                            Access a comprehensive dashboard for in-depth analytics of the token and its holders
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ForthPage",
}
</script>
<style scoped>
h1 {
    font-size: 48px;
    margin-bottom: 20px;
    max-width: 550px;
}

h2 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 40px;
    margin-left: 4px;
    max-width: 65%;
}

button {
    color: #0500FD;
    border-color: #0500FD;
    margin-left: 3px;
}

.page-content {
    display: flex;
}

.left-side {
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
}

.left-side-content {
    margin-left: 50px;
}

.right-side {
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F1F1F1;
}

.right-side-content {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.card-row {
    display: flex;
    gap: 20px;
}

.card {
    width: 400px;
    height: 180px;
    background-color: white;
    padding: 20px 0;
    padding-right: 10px;
    padding-left: 30px;
}

.card-content-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
}

.card-content {
    font-size: 20px;
    line-height: 32px
}

.card-icon-wrapper {
    height: 36px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.card-icon.flexible-trading-strategies {
    height: 28px;
    content: url('/public/icons/chart.svg');
    margin-left: -2px;
}

.card-icon.volume-generation {
    height: 22px;
    content: url('/public/icons/volume.svg');
}

.card-icon.funds-safety {
    height: 36px;
    content: url('/public/icons/safe.svg');
    margin-left: -6px;
}

.card-icon.no-profit-fees {
    height: 24px;
    content: url('/public/icons/sale.svg')
}
.card-icon.telegram-alerts {
    height: 22px;
    content: url('/public/icons/telegram.svg')
}
.card-icon.analytical-dashboard {
    height: 22px;
    content: url('/public/icons/dashboard.svg')
}
</style>