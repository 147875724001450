<template>
    <div class="page-content">
        <div class="left-side">
            <div class="left-side-content">
                <h1>
                    DEX market-making solutions exclusively <span class="accent-green">for token owners</span>
                </h1>
                <h3>
                    Unlock the full potential of your token with our advanced DEX tools.
                    Seamlessly boost trading volume, craft precise chart patterns, and maintain price stability effortlessly.
                </h3>
                <button @click="$root.scrollTo('contact')">
                    Let’s talk about it 🤙
                </button>
            </div>
        </div>
        <div class="right-side">
            <video ref="backgroundVideo" autoplay loop muted playsinline>
                <source src="https://storage.yandexcloud.net/bitfusion-public/landing_backgound.mp4" type="video/mp4">
            </video>
            <div class="overlay"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "FirstPage",
}
</script>
<style scoped>
.page-content{
    display: flex;
    position: relative;
    color: white;
}
.left-side {
    height: 100%;
    width: 100%;
    width: 60%;
    display: flex;
    position: absolute;
    align-items: center;
    z-index: 50;
}
.left-side-content{
    padding-left: 50px;
    max-width: 70%;
}
h1{
    font-size: 48px;
    margin-bottom: 20px;
}
h3{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
    margin-left: 4px;
}
button{
    border-color: white;
    color: white;
    margin-left: 3px;
}
.right-side{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
video{
    width: 100%;
}
.overlay{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(5, 0, 67, 0.9) 0%, rgba(20, 0, 255, 0.55) 100%);
}
.accent-green{
    color: rgb(167, 255, 245);
}
</style>