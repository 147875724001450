<template>
    <div class="page-content" id="contact">
        <div class="left-side">
            <div class="left-side-content">
                <h5>Contact us</h5>
                <h1>We’re here <span class='accent'>to help your token</span> on DEX</h1>
                <div class="contacts">
                    <div class="contact-row">
                        <span class="contact-type">
                            <div class="contact-logo email"></div>
                            Email
                        </span>
                        <span class="contact-value"><a href="mailto:sales@bitfusion.trade">sales@bitfusion.trade</a></span>
                    </div>
                    <div class="contact-row">
                        <span class="contact-type">
                            <div class="contact-logo telegram"></div>
                            Telegram
                        </span>
                        <span class="contact-value"><a href="https://t.me/bitfusion_sales">@bitfusion_sales</a></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-side">
            <div class="right-side-content">
                <div class="final-form">
                    <h3 class="accent" v-if="[1, 2, 3].includes(formPage)">{{ formPage }}/3</h3>
                    <div v-if="formPage === 1" class="final-form-page">
                        <div class="form-page-title">What DEX is your token trading on?</div>
                        <div class="form-page-inputs">
                            <input value="Uniswap V2"
                                :class="{ 'selected': formValues.page1.predefined === 'uniswapV2' }"
                                @click="formValues.page1 = { predefined: 'uniswapV2', custom: null }" readonly>
                            <input value="PancakeSwap V2"
                                :class="{ 'selected': formValues.page1.predefined === 'pancakeV2' }"
                                @click="formValues.page1 = { predefined: 'pancakeV2', custom: null };" readonly>
                            <input placeholder="Other..." @click="formValues.page1.predefined = null"
                                v-model="formValues.page1.custom" :class="{ 'selected': formValues.page1.custom }">
                        </div>
                        <div class="form-button-holder">
                            <button :disabled="!formValues.page1.custom && !formValues.page1.predefined"
                                @click="formPage = 2">Next</button>
                        </div>
                    </div>
                    <div v-if="formPage === 2" class="final-form-page">
                        <div class="form-page-title">What market actions are you interested in?</div>
                        <div class="form-page-inputs">
                            <input value="Generate more trading volume"
                                :class="{ 'selected': formValues.page2.predefined === 'volume' }"
                                @click="formValues.page2 = { predefined: 'volume' }" readonly>
                            <input value="Prevent trading downtimes"
                                :class="{ 'selected': formValues.page2.predefined === 'downtimes' }"
                                @click="formValues.page2 = { predefined: 'downtimes' };" readonly>
                            <input value="Enrich the price action"
                                :class="{ 'selected': formValues.page2.predefined === 'Enrich' }"
                                @click="formValues.page2 = { predefined: 'Enrich' };" readonly>
                        </div>
                        <div class="form-button-holder">
                            <button :disabled="!formValues.page2.predefined" @click="formPage = 3">Next</button>
                            <button class="back-btn" @click="formPage = 1">Back</button>
                        </div>
                    </div>
                    <div v-if="formPage === 3" class="final-form-page">
                        <div class="form-page-title">How we can reach you?</div>
                        <div class="form-page-inputs">
                            <input placeholder="Your name" v-model="formValues.page3.name"
                                :class="{ 'selected': formValues.page3.name }">
                            <input placeholder="Link to your token (website, DEXtools)" v-model="formValues.page3.link"
                                :class="{ 'selected': formValues.page3.link }">
                            <input placeholder="Email / Telegram / LinkedIn" v-model="formValues.page3.contact"
                                :class="{ 'selected': formValues.page3.contact }">
                        </div>
                        <div class="form-button-holder">
                            <button
                                :disabled="!formValues.page3.name || !formValues.page3.link || !formValues.page3.contact" @click="submitForm">Submit</button>
                            <button class="back-btn" @click="formPage = 2">Back</button>
                        </div>
                    </div>
                    <div v-if="formPage === 'success'" class="final-form-page success">
                        That's it!<br>
                        We will reach you out soon!
                    </div>
                    <div v-if="formPage === 'error'" class="final-form-page error">
                        Oops! An error occurred. Please try again or write us directly
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-container">
        <span>Copyright © 2024 - All Rights Reserved. A Product of Bitfusion</span>
    </div>
</template>
<script>
import axios from "axios";

export default {
    name: "LastPage",
    data() {
        return {
            formPage: 1,
            formValues: {
                page1: {
                    predefined: null,
                    custom: null,
                },
                page2: {
                    predefined: null,
                },
                page3: {
                    name: null,
                    link: null,
                    contact: null,
                }
            }
        }
    },
    methods: {
        async submitForm() {
            try {
                const response = await axios.post(
                    "https://getform.io/f/pbqgjzdb",
                    this.formValues,
                    { headers: { 'Accept': 'application/json' } }
                )
                if (response.status === 200) {
                    this.formPage = 'success'
                } else {
                    this.formPage = 'error'
                }
            } catch (e) {
                console.error(e)
                this.formPage = 'error'
            }
        }
    }
}
</script>
<style scoped>
h1,
h2,
h5 {
    margin: 0;
}

h1 {
    font-size: 96px;
    margin-bottom: 100px;
}

h5 {
    color: #BEBEBE;
    font-size: 24px;
    margin-bottom: 20px;
}

button {
    border-color: #0500FD;
    color: #0500FD;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed
}

.connector {
    width: 100%;
    height: 15px;
    background-color: aliceblue;
}

.page-content {
    display: flex;
}

.left-side {
    position: relative;
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
}

.left-side-content {
    margin-left: 50px;
}

.right-side {
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right-side-content {
    display: flex;
    gap: 20px;
    flex-direction: column;
    background: #F1F1F1;
    width: 90%;
}

.contacts {
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.contact-row {
    width: 280px;
    font-weight: 600;
    color: #303030;
    display: flex;
    justify-content: space-between;
}
.contact-logo{
    width: 20px;
}
.contact-logo.email {
    content: url("/public/icons/email.svg");
}

.contact-logo.telegram {
    content: url("/public/icons/telegram.svg");
}

.contact-type {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #0500FD;
}

.contact-value > a{
    text-decoration: none;
    color: rgb(0, 0, 0);
}
.footer-container {
    bottom: 15px;
    color: #BEBEBE;
    margin-top: -30px;
    margin-left: 50px;
}
.final-form {
    padding: 50px;
    background: #F1F1F1;
}

.final-form-page {
    width: 100%;
}

.form-page-title {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 40px;
}

.form-page-inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;
}

.form-page-inputs>input {
    padding: 25px 15px;
    border: none;
    border-radius: 20px;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
}

.form-page-inputs>input:focus {
    outline: none;
}

.form-button-holder {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

input.selected {
    background-color: aliceblue;
}

.back-btn {
    border-color: #6f6f6f;
    color: #6f6f6f;
}
.final-form-page.success, .final-form-page.error{
    font-size: 32px;
    font-weight: 700;
    color: rgb(0, 0, 0)
}

</style>