<template>
    <div class="page-content"  id="quick-setup">
        <div class="left-side">
            <div class="left-side-content">
                <h1>
                    It takes <span class="accent">10 minutes</span><br>to empower your market
                </h1>
                <h2>
                    A personal account will be set up for you on our platform.
                    Simply log in from your phone or computer to monitor market activities from anywhere, at any time.
                </h2>
                <button @click="$root.scrollTo('contact')">
                    Let’s collaborate
                </button>

                <div class="platforms-container">
                    <div class="platforms">
                        <div class="platforms-header">Blockchains</div>
                        <div class="platforms-list">
                            <div class="platforms-icon" v-for="url in networks" :key="url" v-bind:style="`content: url(${url})`"></div>
                            <div class="platforms-other">and any other EVM</div>
                        </div>
                    </div>
                    <div class="platforms">
                        <div class="platforms-header">DEXs v2 (v3 connector is under development)</div>
                        <div class="platforms-list">
                            <div class="platforms-icon" v-for="url in swappers" :key="url" v-bind:style="`content: url(${url})`"></div>
                            <div class="platforms-other">and others per request</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-side">
            <div class="right-side-content">
                <div class="card-row">
                    <div class="card">
                        <div class="card-content-wrapper">
                            <div class="card-header">
                                <h1>01</h1>
                                <h3>- 1 minute</h3>
                            </div>
                            <div class="card-content">
                                We add your token’s pair to initialize the environment on the trading platform.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-content-wrapper">
                            <div class="card-header">
                                <h1>02</h1>
                                <h3>- 3 minutes</h3>
                            </div>
                            <div class="card-content">
                                You send native tokens to deploy trading smart-contracts We deploy it.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card">
                        <div class="card-content-wrapper">
                            <div class="card-header">
                                <h1>03</h1>
                                <h3>- 6 minutes</h3>
                            </div>
                            <div class="card-content">
                                We grant you the dashboard access and provide a guidance on how to use the platform.
                            </div>
                        </div>
                    </div>
                    <div class="card accent">
                        <div class="card-content-wrapper">
                            <div class="card-header">
                                <h1>04</h1>
                                <h3>- instantly</h3>
                            </div>
                            <div class="card-content">
                                You enjoy the most user-friendly Market-Making as a Service solution for DEXs 🥰
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ThirdPage",
    data() {
        return {
            networks: [
                "https://storage.yandexcloud.net/bitfusion-public/logos/networks/ethereum.png",
                "https://storage.yandexcloud.net/bitfusion-public/logos/networks/bsc.png",
                "https://storage.yandexcloud.net/bitfusion-public/logos/networks/base.png",
                "https://storage.yandexcloud.net/bitfusion-public/logos/networks/arbitrum.png",
                "https://storage.yandexcloud.net/bitfusion-public/logos/networks/avalanche.png",
                "https://storage.yandexcloud.net/bitfusion-public/logos/networks/polygon.png",
            ],
            swappers: [
                "https://storage.yandexcloud.net/bitfusion-public/logos/swappers/uniswap.png",
                "https://storage.yandexcloud.net/bitfusion-public/logos/swappers/pancake.png",
                "https://storage.yandexcloud.net/bitfusion-public/logos/swappers/biswap.png",
                "https://storage.yandexcloud.net/bitfusion-public/logos/swappers/dodo.png",
                "https://storage.yandexcloud.net/bitfusion-public/logos/swappers/traderjoe.png",
                "https://storage.yandexcloud.net/bitfusion-public/logos/swappers/pangolin.png",
                "https://storage.yandexcloud.net/bitfusion-public/logos/swappers/sushi.png",
                "https://storage.yandexcloud.net/bitfusion-public/logos/swappers/thenafusion.png",
            ],
        }
    }
}
</script>
<style scoped>
h1 {
    font-size: 48px;
    margin-bottom: 20px;
}

h2 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 40px;
    margin-left: 4px;
    max-width: 65%;
}

button {
    color: #0500FD;
    border-color: #0500FD;
    margin-left: 3px;
}

.page-content {
    display: flex;
}

.left-side {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
}

.left-side-content {
    margin-left: 50px;
}

.right-side {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: azure; */
}

.right-side-content {
    display: flex;
    gap: 20px;
    flex-direction: column;
}
.card-row{
    display: flex;
    gap: 20px;
}
.card {
    width: 260px;
    height: 260px;
    background-color: #F1F1F1;
    padding: 30px;
}
.card-content-wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

}
.card-header {
    display: flex;
    gap: 10px;
    align-items: flex-end;
}

.card-header>h1 {
    margin: 0;
}

.card-header>h3 {
    margin-bottom: 10px;
}

.card-content {
    font-size: 24px;
}
.platforms-container{
    margin-top: 40px;
}
.platforms-container > :first-child{
    margin-bottom: 20px;
}
.platforms-header{
    margin-bottom: 10px;
}
.platforms-list{
    display: flex;
    align-items: center;
    gap: 10px;
}
.platforms-other{
    padding: 4px 8px;
    background-color: #eee;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
}
.platforms-icon{
    width: 30px;
}
</style>